@use '@/scss/colors' as color;
@use "@/scss/fonts" as font;
@use "@/scss/mixins" as mixins;


.root {
    height: 100% !important;
    background-color: color.$white-color;
    font-family: var(--font-source-sans-pro);
    display: flex;
    flex-direction: column;
    padding: 2%;
    overflow: hidden !important;
    min-height: 500px;

    
    /* stylelint-disable-next-line */
@media screen and (max-width: mixins.$mobile-max-width) {
        padding: 5%;
        min-height: 300px;
    }
}