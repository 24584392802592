@use '@/scss/colors' as color;

.notification-container {
    box-sizing: border-box;
    color: color.$grey-intermediate;
    font-size: 14px;
    position: fixed;
    z-index: 999999;
}

.top-right {
    animation: toast-in-right 0.7s;
    right: 12px;
    top: 12px;
    transition: transform 0.6s ease-in-out;
}

.bottom-right {
    animation: toast-in-right 0.7s;
    bottom: 12px;
    right: 12px;
    transition: transform 0.6s ease-in-out;
}

.top-left {
    animation: toast-in-left 0.7s;
    left: 12px;
    top: 12px;
    transition: transform 0.6s ease-in;
}

.bottom-left {
    animation: toast-in-left 0.7s;
    bottom: 12px;
    left: 12px;
    transition: transform 0.6s ease-in;
}

.notification {
    background: #fff;
    background-position: 15px;
    background-repeat: no-repeat;
    border-radius: 3px;
    box-shadow: 0 0 10px #999;
    color: color.$grey-intermediate !important;
    margin: 0 0 6px;
    margin-bottom: 15px;
    max-height: 100px;
    opacity: 0.9;
    overflow: hidden;
    padding: 30px;
    pointer-events: auto;
    position: relative;
    transition: 0.3s ease;
    width: 300px;
}

.notification:hover {
    box-shadow: 0 0 12px #fff;
    cursor: pointer;
    opacity: 1;
}

.notification-title {
    color: color.$grey-intermediate !important;
    font-size: 16px;
    font-weight: 700;
    height: 18px;
    margin-bottom: 6px;
    margin-top: 0;
    text-align: left;
    width: 300px;
}

.notification-message {
    color: color.$grey-intermediate !important;
    font-size: 14px;
    height: 18px;
    line-height: 15px !important;
    margin: 0;
    margin-left: -1px;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.notification-image {
    float: right;
    margin-right: 15px;
}

.notification-image img {
    height: 30px;
    width: 30px;
}

.toast {
    color: color.$grey-intermediate;
    height: 80px;
    padding: 20px 15px 10px 10px;
}

.color-success {
    background-color: #5cb85c !important;
}

.color-warning {
    background-color: #f0ad4e !important;
}

.color-error {
    background-color: #d9534f !important;
}

.color-info {
    background-color: #5bc0de !important;
}

.notification-container button {
    background: 0 0;
    border: unset;
    border: 0;
    color: color.$grey-intermediate;
    cursor: pointer;
    float: right;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    opacity: 0.8;
    outline: none;
    padding: 0;
    position: relative;
    right: -0.3em;
    text-shadow: 0 1px 0 #fff;
    top: -0.3em;
}

@keyframes toast-in-right {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes toast-in-left {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0);
    }
}

@media only screen and (width <= 480px) {
    .top-right {
        right: 0 !important;
        width: 100%;
    }

    .toast.show {
        display: block !important;
        margin: auto !important;
    }
}
