/* colores principales */
$black-color: rgb(31 31 31); // #1f1f1f
$grey-dark: rgb(77 77 77); // #4d4d4d
$grey-intermediate: rgb(162 162 163);
$grey-light: rgb(196 195 197); // #C4C3C5
$grey-light-plus: #e8e8e8;
$turquoise-dark: rgb(26 149 137); // #1A9589
$secondary-color: rgb(3 218 197); // #03DAC5

/* colores para fondos */
$white-color: #fff;
$grey-background: rgb(243 242 245); // #F3F2F5

/* colores complementarios */
$primary-color: rgb(41 148 230); // #2994E6
$green-color: rgb(2 227 145); // #02E391
$yellow-color: rgb(253 223 74); // #FDDF4A

/* colores materiales */
$fiber-color: rgb(41 148 230); // #2994E6
$electronic-color: rgb(204 64 110); // #CC406E
$plastic-color: rgb(253 223 74); // #FDDF4A
$danger-color: rgb(206 62 80); // #CE3E50
$aluminium-color: rgb(162 162 163); // #A2A2A3
$glass-color: rgb(101 192 90); // #65C05A
$tetrapack-color: rgb(224 203 166); // #E0CbA6
$organic-color: rgb(236 129 33); // #EC8121
$wood-color: rgb(142 125 130); // #8E7D82
$bulky-items-color: rgb(26 149 137); // #1A9589
$textile-color: rgb(3 218 197); // #03DAC5
$trash-color: rgb(91 97 115); // #5B6173

/* otros colores */
$black-light: rgb(77 77 77);
$red-color: #f00;
$pink-color: #ffc0cb;
$green-color: #008000;
