@use './colors' as color;
@use './mixins' as mixins;
/* stylelint-disable-next-line */
:root {
    /* fonts */
    --font-source-sans-pro: 'Source Sans Pro', sans-serif !important;
    --breakpoint: 300px;

    /* stylelint-disable-next-line */
    @media screen and (max-width: mixins.$mobile-max-width) {
        --font-source-sans-pro: 'Source Sans Pro', sans-serif !important;
    }
}

// Define tus variables de texto
$bigtitle-size: 1.2vw; // 17px
$bigtitle-weight: bold;
$bigtitle-color: color.$black-color;
$title-size: 1vw; // 17px
$title-weight: bold;
$title-color: color.$black-color;
$subtitle-size: 0.9vw; // 17px
$subtitle-weight: normal;
$subtitle-color: color.$grey-dark;
$subsubtitle-size: 0.7vw; // 14px
$subsubtitle-weight: normal;
$subsubtitle-color: color.$grey-dark;
$body-size: 1vw; // 11px
$body-weight: normal;
$button-size: 0.8vw; // 14px
$button-weight: normal;
$button-color: color.$white-color;
